import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useAllTasks(variables, options) {
  const { error } = useNotification();

  const { result, loading, refetch, onError } = useQuery(TASKS_QUERY, variables, options);

  const totalCount = computed(() => result.value?.tasksNew2.totalCount ?? 0);
  const tasks = computed(() => result.value?.tasksNew2.nodes ?? []);

  onError((param) => {
    console.error('tasks - useTasks', param);
    error();
  });

  return {
    tasks,
    refetch,
    totalCount,
    loading,
  };
}

const TASKS_QUERY = gql`
  query allTasks(
    $businessIds: [ID!]
    $limit: Int
    $offset: Int
    $completed: Boolean
    $domains: [String]
    $types: [String]
    $completedAtFrom: Timestamp
    $completedAtTo: Timestamp
    $createdAtFrom: Timestamp
    $createdAtTo: Timestamp
    $completedBy: [String]
    $reviewRequired: Boolean
  ) {
    tasksNew2(
      businessIds: $businessIds
      limit: $limit
      offset: $offset
      completed: $completed
      domains: $domains
      types: $types
      completedAtFrom: $completedAtFrom
      completedAtTo: $completedAtTo
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
      completedBy: $completedBy
      reviewRequired: $reviewRequired
    ) {
      nodes {
        type
        reviewRequired
        id
        businessId
        createdAt
        activeAt
        completedAt
        completedBy: completedByNew {
          firstName: given_name
          lastName: family_name
        }
        domain
        data
      }
      totalCount
    }
  }
`;
